import React from "react"

import Page from "../../components/Page"
import Quote from "../../components/Site/Quote"
import Paragraph from "../../components/Site/Paragraph"
import PricingForm from "../../components/PricingForm"
import P60Form from "../../components/P60Form"

const TaxRelief = () => {
  const text = `Osoba będąca rezydentem podatkowym w Irlandii może
   aplikować o <b>ulgę małżeńską jeśli wspołmażonek przebywa w innym kraju.</b>
   Ulga należy się nawet wtedy, gdy współmałżonek osiąga w tamtym kraju
    dochody – warunkiem jest oczywiście ich wysokość.`

  const text2 = `Zgodnie z przepisami o agregacji podatkowej w krajach Unii, podatnik, którego współmałzonek 
  przebywa poza Irlandia w ciągu roku będzie mial pobierany podatek wg ulg osoby stanu wolnego. 
  Po przedstawieniu odpowiedniej dokumentacji moża starać o <b>zwrot podatku nawet do kwoty
   €1,650 za każdy rok.</b> Zgłoś się do biura D&M Services aby sprawdzić czy należy 
   Ci się zwrot podatku!`


  return (
    <Page title="ulga podatkowa">
      <h2 className="site-title">Ulga podatkowa</h2>
      <div className='pricing-wrapper'>
        <div className='pricing-text'>
          <Quote text="Ulga podatkowa na współmałżonka mieszkającego poza Irlandią" />
          <Paragraph text={text} />
          <Paragraph text={text2} />
          <Quote text="Klient naszego biura w Dublinie otrzymał zwrot podatku w wysokości €11,228.72" />
          <Quote
            text="Rekordowy zwrot podatku w kwocie €14,986,13 w marcu 2017 roku otrzymał Pan Mariusz z Cork który rozliczył sie w biurze D&M Services z p60 za cztery lata – 2013, 2014, 2015 i 2016" />
          <Quote text="Zgłoś się do nas i sprawdź czy Tobie również zwrot się należy!" />
        </div>
        <P60Form />
      </div>
    </Page>
  )
}

export default TaxRelief
