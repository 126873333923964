import React from "react"

import Page from "../../components/Page"
import Quote from "../../components/Site/Quote"
import Paragraph from "../../components/Site/Paragraph"
import PricingForm from "../../components/PricingForm"

const VirtualOffice = () => {
  const text = `Biuro wirtualne jest usługą zapewniającą realny adres dla
 Twojej firmy bez konieczności prowadzenia działalności w danym miejscu.
  Nasi klienci uzyskują prawo do zarejestrowania swojej działalności
   pod adresem w Dublinie i posługiwania się nim w korespodencji urzędowej
    czy kontaktach z kontrahentami.`

  const text2 = `Rejestrując firmę w biurze wirtualnym czerpiesz benefity wynikające 
  z redukcji kosztów wynajmu lokalu oraz zatrudniania pracowników biurowych.
   Zyskujesz pełne wsparcie naszych księgowych i prawników oraz doradztwo na każdym kroku Twojej drogi biznesowej. 
  Adres firmy w Dublinie wzmocni Twoją pozycję w oczach klientów bez względu
   na to czy prowadzisz działalność w domu, czy wykonujesz wolny zawód.`

  return (
    <Page title="dla firm">
      <h2 className="site-title">Wirtualne biuro</h2>
      <div className='pricing-wrapper'>
        <div className='pricing-text'>
          <Quote text="Tak może wyglądać pieczątka Twojej firmy!" />
          <b>Nazwa twojej firmy</b><br />
          <b>Unit 26 Park West Enterprise Centre</b><br />
          <b>Nangor Rd</b><br />
          <b>Dublin 12</b><br />
          <b>REG NO: 1234567890</b><br />
          <Quote text="Czym jest biuro wirtualne?" />
          <Paragraph text={text} />
          <Paragraph text={text2} />
          Usługi:
          <ul className='about-us-bullets'>
            <li>
              Adres do rejestracji spółki
            </li>
            <li>
              Odbiór korespondencji zwykłej i poleconej
            </li>
            <li>
              Informacje o otrzymywaniu przesyłek drogą mailową
            </li>
            <li>
              Wysyłanie skanów korespondencji
            </li>
          </ul>
          Koszt: €35 / miesiąc<br />
          Stała opłata roczna: €250 (+ VAT)
          <Quote text="Zadzwoń lub napisz już teraz!" />
        </div>
        <PricingForm />
      </div>
    </Page>
  )
}

export default VirtualOffice
