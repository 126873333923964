const data = {
  achievements: [
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/102320251_2790686647723495_196430934872948736_n.jpg?_nc_cat=110&_nc_sid=730e14&_nc_ohc=7IBKxQnLDxsAX8Wh8y-&_nc_ht=scontent-waw1-1.xx&oh=8aa620dd7ce4b5590b163beec6fed3b2&oe=5F893524`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/83551550_2790686441056849_6919589851733950464_n.jpg?_nc_cat=105&_nc_sid=730e14&_nc_ohc=wR3zP2ZCg_sAX_ePE2D&_nc_ht=scontent-waw1-1.xx&oh=1878df28543c67c4c70e3410bed0d0b7&oe=5F8B1CED`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/99130355_2761117177347109_7385677443543072768_n.jpg?_nc_cat=110&_nc_sid=730e14&_nc_ohc=AbLA1MzmECYAX9DXn1x&_nc_ht=scontent-waw1-1.xx&oh=edad1328c8ad0570799013ead9bad0f2&oe=5F8943D9`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/99245227_2761117060680454_201257017824772096_n.jpg?_nc_cat=106&_nc_sid=730e14&_nc_ohc=wtcL4cdRj9EAX8WGgTP&_nc_oc=AQlH8nbBkToI7_paoJuAA7AKZs_YVF4cS0ZRUCJF2V8hfdj4RcxJaamd67-9OazQ8bc&_nc_ht=scontent-waw1-1.xx&oh=c9772c5a8e0f3353c8e2669126f8ad14&oe=5F8935A6`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/99002231_2761116907347136_8242378751711641600_n.jpg?_nc_cat=110&_nc_sid=730e14&_nc_ohc=biXcYp0BlhcAX--IOnX&_nc_ht=scontent-waw1-1.xx&oh=f0e691d76fd6f2c13a7c3bf762971114&oe=5F894700`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/99010771_2761116777347149_880301079718264832_n.jpg?_nc_cat=102&_nc_sid=730e14&_nc_ohc=r9dRDk3Wcn0AX_UFLv-&_nc_ht=scontent-waw1-1.xx&oh=decb9369584a18836f5ab2dbe804f867&oe=5F88EC4B`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/99094346_2761116617347165_4946710131988496384_n.jpg?_nc_cat=110&_nc_sid=730e14&_nc_ohc=arGr3tw6BT4AX-_Ivk7&_nc_ht=scontent-waw1-1.xx&oh=c30f0dd41f810a1c428bf6344e02d12f&oe=5F8BC18E`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/97135367_2745848958873931_3082707236196188160_n.jpg?_nc_cat=109&_nc_sid=730e14&_nc_ohc=QN7q4qQtzgwAX_C9kse&_nc_ht=scontent-waw1-1.xx&oh=d51df503c1723ed53023092ed04b9f31&oe=5F89C915`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/97106141_2745848828873944_2376704102672367616_n.jpg?_nc_cat=103&_nc_sid=730e14&_nc_ohc=uBJFIZ7n9skAX_4AxKU&_nc_ht=scontent-waw1-1.xx&oh=af58f61df1c643b727863d5f37b47b41&oe=5F8A7FE6`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/97977492_2745848672207293_5687349947666530304_n.jpg?_nc_cat=108&_nc_sid=730e14&_nc_ohc=G9OTFTXQF9sAX95CUB8&_nc_ht=scontent-waw1-1.xx&oh=178f6245edc5c898c7d824b819feff80&oe=5F8A0742`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/97980343_2745848492207311_3656349613780107264_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=36lsjvMM8V0AX_O0K-h&_nc_ht=scontent-waw1-1.xx&oh=328190da6a4034d69ea673ccff4cd37d&oe=5F8AF605`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/96764718_2734676663324494_6482744029363568640_n.jpg?_nc_cat=107&_nc_sid=730e14&_nc_ohc=s80sqOjt4yUAX_SH1v5&_nc_ht=scontent-waw1-1.xx&oh=8ef2d7df808bb4589f370d9739406c76&oe=5F89FB33`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/96512244_2734676453324515_5406334472327004160_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=5SmJ7pBi2g0AX_5q9iA&_nc_ht=scontent-waw1-1.xx&oh=a4e3f4ba36a49bb79496196532358383&oe=5F8A742F`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/96231748_2734676273324533_5880800024912199680_n.jpg?_nc_cat=109&_nc_sid=730e14&_nc_ohc=dxcth3GU98cAX-Ldi4N&_nc_ht=scontent-waw1-1.xx&oh=e1911962f1e5bcf2e7ecb5de36d140a2&oe=5F8BBB0D`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/96686254_2734676099991217_8403279311930392576_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=v29lMhgPfvUAX_fBTb3&_nc_ht=scontent-waw1-1.xx&oh=7de50d7bbfae45d8ef345e96c4a57ca6&oe=5F884962`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/96724360_2734675926657901_1384604976994582528_n.jpg?_nc_cat=106&_nc_sid=730e14&_nc_ohc=IdxJv1F5mtYAX_vxh0K&_nc_oc=AQnzBaxrQWj5qTsWhaM3PtXbSf53GtRKdraxx-w2uYbp6RFKeb11uuD2W1NWUiBgauA&_nc_ht=scontent-waw1-1.xx&oh=f4cdb3967aba2ffbb07464ff637fad80&oe=5F89E748`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/97202252_2734675706657923_8924893842965004288_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=Kar15bUcXmAAX8q287Z&_nc_ht=scontent-waw1-1.xx&oh=c08f9683553e15bcce138d46e6963be1&oe=5F8880A8`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/95932363_2721151818010312_8445328321658486784_n.jpg?_nc_cat=106&_nc_sid=730e14&_nc_ohc=tWFb8NRA3ZMAX-3Nl26&_nc_ht=scontent-waw1-1.xx&oh=fbbe7571319338847ffce08b016df8bd&oe=5F8BAA39`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/95901372_2721151661343661_4190030395871330304_n.jpg?_nc_cat=100&_nc_sid=730e14&_nc_ohc=ypQzzzpmDrUAX_rvSGp&_nc_ht=scontent-waw1-1.xx&oh=260d4bdafdbe1dc5864edd77b639000a&oe=5F8BF37A`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/95779491_2721151481343679_5843604014695972864_n.jpg?_nc_cat=104&_nc_sid=730e14&_nc_ohc=mGXxt-mvhrUAX9OEHT6&_nc_ht=scontent-waw1-1.xx&oh=564e7a77344b55b791258cf2c62ce0ab&oe=5F886674`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/95290859_2712024982256329_9016895529958768640_n.jpg?_nc_cat=105&_nc_sid=730e14&_nc_ohc=CvEzFrTRXnAAX-H7WPF&_nc_ht=scontent-waw1-1.xx&oh=01cde0404cdbb59067fe04c301e8f762&oe=5F8B6355`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/95257324_2712024862256341_8204131037148610560_n.jpg?_nc_cat=102&_nc_sid=730e14&_nc_ohc=SitNp8pYprgAX-NCq0u&_nc_ht=scontent-waw1-1.xx&oh=ca3c081149d750fb76f30d8a05bc548f&oe=5F8A7DFC`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/95602924_2712024782256349_7102451468795379712_n.jpg?_nc_cat=110&_nc_sid=730e14&_nc_ohc=fuIPMTOTsgsAX_QQRsE&_nc_ht=scontent-waw1-1.xx&oh=58426b11adb731b10df399f32ab0420c&oe=5F8B58AB`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/95220240_2712024612256366_8165073429192507392_n.jpg?_nc_cat=108&_nc_sid=730e14&_nc_ohc=9sT1bXmtXuEAX-I0Iqn&_nc_ht=scontent-waw1-1.xx&oh=48c9b50599c4ea0c7f9f0b9f5a9fa699&oe=5F88729E`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/95331481_2712024488923045_793630645434712064_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=N_IPUAUhjwQAX8z-iVy&_nc_ht=scontent-waw1-1.xx&oh=001f41119133d929d16791a4f3af2634&oe=5F8A4F75`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/91767736_2655200647938763_4586417935551037440_n.jpg?_nc_cat=103&_nc_sid=730e14&_nc_ohc=atjYkgP2zkEAX-KIgiq&_nc_ht=scontent-waw1-1.xx&oh=8bee1493a97db707c2c82a5e9e8dd168&oe=5F8B68CF`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/91805601_2655200544605440_7081597946579386368_n.jpg?_nc_cat=107&_nc_sid=730e14&_nc_ohc=XH4EiM1aKugAX-14c8C&_nc_ht=scontent-waw1-1.xx&oh=1bf177ce8d951b8ed45f72a661f78900&oe=5F884265`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/91893109_2655200157938812_6526918665651093504_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=VPrBbpU5-E0AX8PSQXN&_nc_ht=scontent-waw1-1.xx&oh=8db271cae08d72d85b327158e9d9d966&oe=5F88374D`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/91997347_2655199787938849_6108003988144128000_n.jpg?_nc_cat=102&_nc_sid=730e14&_nc_ohc=TPeqY2jhq7EAX8EmDSf&_nc_ht=scontent-waw1-1.xx&oh=e40ef4312e02708bef590bd615020351&oe=5F899360`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/87831829_2583684188423743_7547842150075990016_n.jpg?_nc_cat=104&_nc_sid=730e14&_nc_ohc=wRyzk_z3JhMAX_wAhLt&_nc_ht=scontent-waw1-1.xx&oh=d8c1bba79a1a37c2658007da4afca78c&oe=5F8A9009`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/87831816_2583684065090422_7822844632546410496_n.png?_nc_cat=108&_nc_sid=730e14&_nc_ohc=PiG-sv4Gu9QAX9-_I8I&_nc_ht=scontent-waw1-1.xx&oh=bfe52d1fd410d4308410785e15d67b5f&oe=5F88AB37`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/88228496_2583683955090433_2621558899727138816_n.png?_nc_cat=111&_nc_sid=730e14&_nc_ohc=8qQuCRKUeIQAX_opYC3&_nc_ht=scontent-waw1-1.xx&oh=6b96a2d21a9c8ba52f05a8c8f3296078&oe=5F892778`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/88303440_2583682921757203_7200617438881251328_n.png?_nc_cat=111&_nc_sid=730e14&_nc_ohc=ndrBsZpwM-kAX-1TWqD&_nc_ht=scontent-waw1-1.xx&oh=175706cf920e816bc2ab76ed61baceb5&oe=5F8811FE`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/65886707_2122274157898084_7102472793308004352_n.jpg?_nc_cat=103&_nc_sid=730e14&_nc_ohc=lhbxbpRlsqgAX-VWfm2&_nc_ht=scontent-waw1-1.xx&oh=e373613511b41d1659e137aefd62756f&oe=5F897680`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/65767580_2122274031231430_2848439250058215424_n.jpg?_nc_cat=110&_nc_sid=730e14&_nc_ohc=V2X61FUJwlgAX8JWa0H&_nc_ht=scontent-waw1-1.xx&oh=995e938f48ed186693270a787471e6a7&oe=5F88C788`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/57614991_2011205719004929_1813911499672911872_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=cCLCyFSZKnwAX_ug8hr&_nc_ht=scontent-waw1-1.xx&oh=756ba819d1e84efecfc2017ce0b0362e&oe=5F8B095C`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/57592808_2011205715671596_8396486550568828928_n.jpg?_nc_cat=101&_nc_sid=730e14&_nc_ohc=iGVravdIn0YAX9AyXmq&_nc_ht=scontent-waw1-1.xx&oh=2d10bd62cb72c8f25fc4faf65475d112&oe=5F883FF1`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/57540157_2011205685671599_9044988378465959936_n.jpg?_nc_cat=105&_nc_sid=730e14&_nc_ohc=mOKI2o9sTcUAX8jGoOK&_nc_ht=scontent-waw1-1.xx&oh=ed2b60f44512a708b96acef4feed1211&oe=5F8B7E76`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/51882851_1911791065613062_776495409550852096_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=Zd1HhScY_RwAX9aUbHt&_nc_ht=scontent-waw1-1.xx&oh=cd1743ac774084ea9dcd420488e7726a&oe=5F8A9985`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/38693308_1652806438178194_4283175656562360320_n.jpg?_nc_cat=111&_nc_sid=730e14&_nc_ohc=nPdWHpoY_PoAX-fQbui&_nc_ht=scontent-waw1-1.xx&oh=dde8247e285dd934482b53c5209bc481&oe=5F8B382D`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/38254831_1644931242299047_156861345340325888_n.jpg?_nc_cat=104&_nc_sid=730e14&_nc_ohc=7tFHa6cVpQkAX_f2iTj&_nc_ht=scontent-waw1-1.xx&oh=905e67e680dd1c2d72929c822f5a47f8&oe=5F88737C`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/38243756_1644931168965721_5534537433636405248_n.jpg?_nc_cat=107&_nc_sid=730e14&_nc_ohc=uNJwuEFlM5kAX_FB5rn&_nc_ht=scontent-waw1-1.xx&oh=db0c81f83d34469f4893349145751875&oe=5F8ACBE6`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/38267319_1644931158965722_2221703788136235008_n.jpg?_nc_cat=107&_nc_sid=730e14&_nc_ohc=AYaCxlYRt1UAX9WTKcg&_nc_ht=scontent-waw1-1.xx&oh=1f25e116aaa35b30b4dd34ade06f05f6&oe=5F886CCD`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/38224956_1644931155632389_1193004462990426112_n.jpg?_nc_cat=109&_nc_sid=730e14&_nc_ohc=TwhYAOgIHCkAX_fNGzG&_nc_ht=scontent-waw1-1.xx&oh=a91d3423c5024de2598d3db436cec116&oe=5F8AA33A`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/31301999_1518151891643650_7399261819315062030_n.jpg?_nc_cat=104&_nc_sid=730e14&_nc_ohc=HSSjT1khb7gAX9XbhqR&_nc_ht=scontent-waw1-1.xx&oh=23ff454a39a9d885ea920427730537ab&oe=5F899539`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/31364218_1518151881643651_1961570306419327945_n.jpg?_nc_cat=110&_nc_sid=730e14&_nc_ohc=wFQoXCvmvSAAX_hYqZC&_nc_ht=scontent-waw1-1.xx&oh=55d0b37fb0b3f16f79e8d0843b0fffcb&oe=5F8B8BA2`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/31376072_1518151814976991_7970636772414298040_n.jpg?_nc_cat=109&_nc_sid=730e14&_nc_ohc=IVE9ExbMhaMAX_jCjf1&_nc_ht=scontent-waw1-1.xx&oh=69b00ee5541fa3918e2d58a1caf6afe4&oe=5F8919BB`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/31395678_1518151811643658_4729361941893238706_n.jpg?_nc_cat=104&_nc_sid=730e14&_nc_ohc=vfczmfJkZzIAX_XER46&_nc_ht=scontent-waw1-1.xx&oh=8c2d1c41096291a22f8a0da5fb962722&oe=5F8BA59D`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/31355274_1518151808310325_5621835384097426174_n.jpg?_nc_cat=105&_nc_sid=730e14&_nc_ohc=3ILvgjFSqcQAX-X6vRV&_nc_ht=scontent-waw1-1.xx&oh=2ced4b3f5b0cafa8094fd3a969d4538f&oe=5F88B892`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/29791677_1497489150376591_5116009121069059453_n.jpg?_nc_cat=103&_nc_sid=730e14&_nc_ohc=uCkelO8Tmu4AX948NLs&_nc_ht=scontent-waw1-1.xx&oh=3fd1ee70782f1e281e37bee6c5217755&oe=5F8AC518`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/30221798_1497489057043267_2835229268904477020_n.jpg?_nc_cat=106&_nc_sid=730e14&_nc_ohc=kx4Od3MtEYIAX8UcAid&_nc_ht=scontent-waw1-1.xx&oh=bc910d02295a8e0efdbf434867999fd4&oe=5F8A17D0`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/30226095_1497489000376606_5592924354965536341_n.jpg?_nc_cat=107&_nc_sid=730e14&_nc_ohc=5c4Q1HAclMQAX-xNZI2&_nc_ht=scontent-waw1-1.xx&oh=1189695eedbda8ca7ba4579f50497dc2&oe=5F8A824C`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/26229377_1411330838992423_5602613830926513018_n.jpg?_nc_cat=101&_nc_sid=730e14&_nc_ohc=ipWsCAXhr9QAX9qVChQ&_nc_ht=scontent-waw1-1.xx&oh=b3696231e01c72d45338aed8b6c175ff&oe=5F89D859`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t31.0-8/26840708_1411330722325768_1666993191743803730_o.jpg?_nc_cat=105&_nc_sid=730e14&_nc_ohc=o24nyqcjfBAAX8A_EV5&_nc_ht=scontent-waw1-1.xx&oh=426812720f348a70b65249e8596078bd&oe=5F8A1B07`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t31.0-8/26678257_1411331418992365_6006094502123058134_o.jpg?_nc_cat=108&_nc_sid=730e14&_nc_ohc=jRie3v2Qj6wAX_JeJ09&_nc_ht=scontent-waw1-1.xx&oh=9fa4cb39190559df28307f151062ed79&oe=5F8A78CB`,
    },
    {
      image: `https://scontent-waw1-1.xx.fbcdn.net/v/t31.0-8/26678571_1411331038992403_3134159701421111215_o.jpg?_nc_cat=101&_nc_sid=730e14&_nc_ohc=WFXsWVUoQ5sAX_WRg4T&_nc_ht=scontent-waw1-1.xx&oh=182dc4f5b16aed6b37542dd6c277803e&oe=5F8BE3A3`,
    },
  ],
  offer: [
    {
      url: "/uslugi/ulga-podatkowa",
      image: "/foto-1.jpg",
      name: "Ulga podatkowa",
    },
    {
      url: "/uslugi/dla-firm",
      image: "/foto-2.jpg",
      name: "Usługi dla firm",
    },
    {
      url: "/uslugi/zwrot-z-p60",
      image: "/foto-3.jpg",
      name: "Zwrot podatku z P60",
    },
  ],
}
export default data
