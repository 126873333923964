import store from "../../redux/store"
import defaultTranslations from "./data"

const translator = (item) => {
  const translations = store.getState().translations.data

  if (translations === undefined) return defaultTranslations[item]

  return (
    translations[item] ||
    defaultTranslations[item] ||
    defaultTranslations["unknown"]
  )
}

export default translator
