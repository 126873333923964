import React from "react"

import * as Icons from "../assets/icons"
import Quote from "./Site/Quote"

const ContactDetails = () => (
  <div>
    <div className="contact-info-wrapper">
      <div className="contact-info">
        <Quote text="Telefon" />
        <table>
          <tr>
            <td>
              Telefon <Icons.Telephone />
            </td>
            <td>
              <a href="tel:016120620">016 12 06 20</a>
            </td>
          </tr>
          <tr>
            <td>
              E-mail <Icons.Contact />
            </td>
            <td>
              <a href="mailto:firmadublin@gmail.com">firmadublin@gmail.com</a>
            </td>
          </tr>
        </table>
      </div>
      <div className="location-address">
        <Quote text="Adres" />
        Unit 26,
        <br /> Park West Enterprise Centre,
        <br /> Nangor Road,
        <br /> Dublin 12, D12 N2X6
      </div>
      <div className="open-hours">
        <Quote text="Otwarcie" />
        <table>
          <tr>
            <td>Poniedziałek</td>
            <td>
              <b style={{ color: "red" }}>zamknięte</b>
            </td>
          </tr>
          <tr>
            <td>Wtorek</td>
            <td>
              <b style={{ color: "green" }}>10 - 17</b>
            </td>
          </tr>
          <tr>
            <td>Środa</td>
            <td>
              <b style={{ color: "green" }}>10 - 17</b>
            </td>
          </tr>
          <tr>
            <td>Czwartek</td>
            <td>
              <b style={{ color: "green" }}>10 - 17</b>
            </td>
          </tr>
          <tr>
            <td>Piątek</td>
            <td>
              <b style={{ color: "orangered" }}> 10 - 16</b>
            </td>
          </tr>
          <tr>
            <td>Sobota</td>
            <td>
              <b style={{ color: "red" }}>zamknięte</b>
            </td>
          </tr>
          <tr>
            <td>Niedziela</td>
            <td>
              <b style={{ color: "red" }}>zamknięte</b>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <Quote text="Mapa" />
      <iframe
        width="100%"
        height="400"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Unit%2026,%20Park%20West%20Enterprise%20Centre,%20Nangor%20Road,%20Dublin%2012,%20D12%20N2X6+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
    </div>
  </div>
)

export default ContactDetails
