import React from "react"

import Page from "../../components/Page"
import Quote from "../../components/Site/Quote"
import Paragraph from "../../components/Site/Paragraph"
import PricingForm from "../../components/PricingForm"
import P60Form from "../../components/P60Form"

const P60 = () => {
  const text = `Czy wiesz, że możesz rozliczyć się z podatku bez wychodzenia z 
  domu i dosłownie od ręki? <b>Zrobimy to za Ciebie!</b> Za darmo sprawdzimy, 
  czy należy Ci się zwrot i dokonamy jego rozliczenia. Wystarczy,
   że do nas zadzwonisz lub napiszesz e-maila, a od razu uzyskasz 
   informację o należnym podatku. Każdy, kto kiedykolwiek rozliczał 
   się w D&M Services, figuruje w naszej bazie danych i 
   <b>nie musi dostarczać osobiście Summary of Employment.</b>`

  const text2 = `Na początku roku pracownicy otrzymają z Revenue Summary of Employment,
   czyli podsumowanie dochodów i podatków z tytułu pracy za ubiegły rok. 
   Wystarczy, że wyślesz do nas ten dokument, a <b>nasi księgowi sprawdzą, 
   czy przysługuje Ci zwrot podatku.</b>`


  return (
    <Page title="zwrot z P60">
      <h2 className="site-title">zwrot podatku</h2>
      <div className='pricing-wrapper'>
        <div className='pricing-text'>
          <Quote text="Rozliczamy podatek od ręki!" />
          <Paragraph text={text} />
          <Paragraph text={text2} />
          <Quote text="Skorzystaj z dziesięcioletniego doświadczenia naszego biura!" />
        </div>
        <P60Form />
      </div>
    </Page>
  )
}

export default P60
