import * as actions from "../actionNames/translations"

export const add = (data) => ({
  type: actions.TRANSLATIONS_ADD,
  data: data,
})

export const edit = (data) => ({
  type: actions.TRANSLATIONS_EDIT,
  data: data,
})

export const remove = (data) => ({
  type: actions.TRANSLATIONS_REMOVE,
  data: data,
})

export const set = (data) => ({
  type: actions.TRANSLATIONS_SET,
  data: data,
})

export const status = (status) => ({
  type: actions.TRANSLATIONS_STATUS,
  status: status,
})
