import { createStore, combineReducers } from "redux"

import exampleStore from "./stores/exampleStore"
import translations from "./stores/translations"
import popups from "./stores/popups"

const store = createStore(
  combineReducers({
    exampleStore,
    translations,
    popups,
  })
)

export default store
