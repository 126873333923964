import React from "react"

import Page from "../../components/Page"
import Quote from "../../components/Site/Quote"
import Paragraph from "../../components/Site/Paragraph"
import PricingForm from "../../components/PricingForm"

const Correspondence = () => {
  const text = `<b>Czy wiedziałeś, że możesz rozliczać swoją firmę drogą korespondencyjną lub przez internet?</b>
    <br/>
    <br/>
    Z naszych usług możesz spokojnie korzystać,  <b>nawet, jeśli nie</b> jesteś z Cork czy Dublina -
    mamy klientów z całej Irlandii, którzy wysyłają dokumenty za 
    pośrednictwem <b>poczty poleconej lub kuriera,</b> więc Ty też możesz tak zrobić.`

  const text2 = `Nasze biuro świadczy kompleksowe usługi <b>księgowo-rachunkowe</b> i <b>kadrowo-płacowe </b>
    dla podmiotów o różnych profilach działalności i formie prawnej.
    Niezbędnych konsultacji można dokonać drogą telefoniczną lub mailową.`


  return (
    <Page title="księgowość korespondencyjna">
      <h2 className="site-title">Księgowość korespondencyjna</h2>
      <div className='pricing-wrapper'>
        <div className='pricing-text'>
          <Paragraph text={text} />
          <Quote text="Z nami oszczędzasz czas i pieniądze" />
          <Paragraph text={text2} />
        </div>
        <PricingForm />
      </div>
    </Page>
  )
}

export default Correspondence
