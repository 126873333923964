import React from "react"
import Slider from "r3smil3-pack"

import Page from "../components/Page"
import Tile from "../components/Tile"
import Quote from "../components/Site/Quote"
import data from "../data/Home"
import Paragraph from "../components/Site/Paragraph"

const Home = () => (
  <Page title="główna">
    <h2 className="site-title">Nasza oferta</h2>
    <div className='site-row'>
      <div className='site-right'>
        <Paragraph text={p1} />
        <Quote text={t1} />
      </div>
      <div className='site-left'>
        {data.offer.map((item) => (
          <Tile {...item} />
        ))}
      </div>
    </div>
    <Quote text="Zaufaj nam i powalcz o należny zwrot!" noCursive />
  </Page>
)

const p1 = `Biuro D&M Accountancy Services działa na irlandzkim rynku <b><u>od 2008 roku</b></u>.
Doświadczenie i renoma naszego biura to dla nas ogromny powód do dumy. Oferujemy usługi kompleksowej księgowości
(rozliczanie VAT, rozliczanie podatku dochodowego), rejestrację działalności, tworzenie biznesplanów, porady biznesowe,
Payroll oraz roczne rozliczenia już od <u><b>€100</b></u>.`
const t1 = `D&M SERVICES:<br/>Twoja firma księgowo-doradcza w irlandii`

const tilesSettings = {
  autoPlay: {
    on: true,
  },
  arrows: {
    left: {
      content: (
        <div className="arrow left">
          <div>&#x27A4;</div>
        </div>
      ),
    },
    right: {
      content: (
        <div className="arrow right">
          <div>&#x27A4;</div>
        </div>
      ),
    },
  },
  center: true,
  changeTime: 1000,
  id: "tiles-slider",
}

export default Home
