import React from "react"
import { connect } from "react-redux"

import Loader from "./components/Loader"
import Router from "./router"
import store from "./redux/store"
import * as translations from "./redux/actions/translations"
import trans from "./assets/translations/data"

class App extends React.Component {
  state = {
    siteHasLoaded: false,
    currentlyLoading: "Initialising",
  }

  storeFollower = () => {
    console.clear()
    console.log("APP INITIALISED")
    console.log("STARTING STORE STATE:", store.getState())
    store.subscribe(() =>
      console.log("STORE HAS BEEN UPDATED:", store.getState())
    )
  }

  fetchTranslations = () => {
    this.setState({ currentlyLoading: "Loading translations" })
    this.props.dispatch(translations.status("Fetching"))
    this.props.dispatch(translations.set(trans))
    this.props.dispatch(translations.status("Fetched"))
  }

  async componentDidMount() {
    await this.fetchTranslations()
    this.storeFollower()
    this.setState({ siteHasLoaded: true })
  }

  render() {
    const { siteHasLoaded, currentlyLoading } = this.state

    if (!siteHasLoaded) return <Loader text={currentlyLoading} />
    return <Router />
  }
}

export default connect()(App)
