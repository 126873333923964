import React from "react"

function createMarkup(text) {
  return { __html: text }
}

const Paragraph = ({ text }) => (
  <h4 className="paragraph" dangerouslySetInnerHTML={createMarkup(text)}></h4>
)

export default Paragraph
