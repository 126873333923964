import * as actions from "../actionNames/popups"

export const add = (data) => ({
  type: actions.POPUPS_ADD,
  data: data,
})

export const edit = (data) => ({
  type: actions.POPUPS_EDIT,
  data: data,
})

export const remove = (data) => ({
  type: actions.POPUPS_REMOVE,
  data: data,
})

export const set = (data) => ({
  type: actions.POPUPS_SET,
  data: data,
})

export const status = (status) => ({
  type: actions.POPUPS_STATUS,
  status: status,
})
