import React from "react"

import { connect } from "react-redux"
import * as popups from "../../redux/actions/popups"

class Popup extends React.Component {
  timer
  state = {
    countDown: 5000,
    step: 100,
  }

  startTimer = () => {
    this.timer = setInterval(() => {
      let { countDown, step } = this.state
      countDown -= step
      this.setState({ countDown })
    }, 100)
  }

  handleMouseOver = () => {
    clearInterval(this.timer)
  }

  handleMouseOut = () => {
    this.startTimer()
  }

  componentDidMount() {
    this.startTimer()
  }

  componentDidUpdate() {
    let { countDown } = this.state
    const { id } = this.props
    if (countDown !== 0) return
    clearInterval(this.timer)
    this.props.dispatch(popups.remove(id))
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { title, status, text } = this.props

    return (
      <div
        className="popups-inner-wrapper"
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        <h3 className={`popups-title ${status}`}>{title}</h3>
        <p>{text}</p>
      </div>
    )
  }
}

export default connect()(Popup)
