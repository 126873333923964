import React from "react"

import Banner from "./Banner"
import Head from "./Head"
import Menu from "./Menu"
import Popup from "./Popup"
import Footer from "./Footer"
import { connect } from "react-redux"
import store from "../../redux/store"

class Laoyut extends React.Component {
  state = { popups: [] }

  fetchPopups() {
    let popups = store.getState().popups.data
    this.setState({ popups })

    store.subscribe(() => {
      let newPopups = store.getState().popups.data
      this.setState({ popups: newPopups })
    })
  }

  componentDidMount() {
    this.fetchPopups()
  }

  render() {
    const { children, title } = this.props
    const { popups } = this.state

    return (
      <div className="site-wrapper">
        <Head title={title} />
        <Menu />
        <Banner />
        <div className="site-section">{children}</div>
        <div className="popups-wrapper">
          {popups.map((popup) => (
            <Popup key={popup.id} {...popup} />
          ))}
        </div>
        <Footer />
      </div>
    )
  }
}

export default connect()(Laoyut)
