import React from "react"

export default class FacebookPanelTall extends React.Component {
  componentDidMount() {
    let allCorrect

    setTimeout(() => {
      allCorrect = document.getElementsByTagName("blockquote").length === 0
      if (!allCorrect) window.location.reload()
    }, 1000)
  }

  render() {
    return (
      <div
        className="fb-page"
        data-href="https://www.facebook.com/DM-Accountancy-Services-327198004072384"
        data-tabs="timeline,messages"
        data-width=""
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/DM-Accountancy-Services-327198004072384"
          class="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/DM-Accountancy-Services-327198004072384">
            D&amp;M Accountancy Services
          </a>
        </blockquote>
      </div>
    )
  }
}
