import React from "react"

import Page from "../components/Page"
import Quote from "../components/Site/Quote"
import Paragraph from "../components/Site/Paragraph"
import PricingForm from "../components/PricingForm"

const Pricing = () => {
  const text = "Wszystkie ceny ustalane są indywidualnie, w zależności od ilości dokumentów i czasu poświęconego na przygotowanie rozliczenia."

  return (
    <Page title="cennik">
      <h2 className="site-title">Cennik</h2>
      <div className='pricing-wrapper'>
        <div className='pricing-text'>
          <Paragraph text={text} />
          <Quote text="Przykładowe ceny dla osób prywatnych" />
          <ul className='about-us-bullets'>
            <li>
              Ustalenie wysokości zwrotu -
              <i className='pricing-value'><b>bezpłatnie</b></i>
            </li>
            <li>
              Aplikacja o zwrot podatku -
              <i className='pricing-value'>10% wysokości zwrotu</i>
            </li>
          </ul>
          <Quote text="Przykładowe ceny dla firm" />
          <ul className='about-us-bullets'>
            <li>
              Rozliczenie roczne podatku dochodowego, kalkulacje oraz deklaracja Form 11 -
              <i className='pricing-value'> €300 (+ VAT)</i>
            </li>
            <li>
              Payroll -
              <i className='pricing-value'> €35 (+ VAT) / pracownika</i>
            </li>
            <li>
              VAT -
              <i className='pricing-value'> €120 (+ VAT) / miesiąc</i>
            </li>
            <li>
              Aplikacja Tax Clearence -
              <i className='pricing-value'> €50 (+ VAT)</i>
            </li>
            <li>
              Rejestracja działalności gospodarczej w Revenue -
              <i className='pricing-value'> €150 (+ VAT)</i>
            </li>
            <li>
              CRO (rejestracja nazwy dla firmy) -
              <i className='pricing-value'> €50 (+ VAT)</i>
            </li>
            <li>
              Rejestracja w Revenue jako pracodawca -
              <i className='pricing-value'> €40 (+ VAT)</i>
            </li>
            <li>
              Rejestracja w Revenue jako płatnik podatku VAT -
              <i className='pricing-value'> €40 (+ VAT)</i>
            </li>
            <li>
              Listy / zaświadczenia do urzędów, Social Welfare, Revenue, zmiana adresu w urzędach, rozdzielenie ulg
              podatkowych -
              <i className='pricing-value'> €30 (+ VAT)</i>
            </li>
            <li>
              Spotkanie / porada dotycząca założenia i prowadzenia działalności gospodarczej -
              <i className='pricing-value'> €50 (+ VAT)</i>
            </li>
            <li>
              Pakiet dokumentów do aplikacji o mortgage (PnL za 2-3 lata, tax clearance, assessment letter,
              zaświadczenie z
              księgowości) -
              <i className='pricing-value'> €100 (+ VAT)</i>
            </li>
          </ul>
        </div>
        <PricingForm />
      </div>
    </Page>
  )
}

export default Pricing
