import React from "react"

import BigQuote from "../../components/Site/BigQuote"

const Footer = () => {
  return (
    <div>
      <BigQuote text={quote} />
    </div>
  )
}

const quote = `Powierzenie spraw księgowo-rachunkowych oraz socjalnych wyspecjalizowanej
firmie pozwoli Państwu zaoszczędzić cenny <b><u>czas</u></b>, <b><u>pieniądze</u></b> oraz
zagwarantuje finansowe <b><u>bezpieczeństwo</u></b>.`

export default Footer
