const data = [
  {
    type: "paragraph",
    text: `Biuro D&M Accountancy Services działa na irlandzkim rynku <b><u>od 2008 roku</b></u>.
    Doświadczenie i renoma naszego biura to dla nas ogromny powód do dumy. Oferujemy usługi kompleksowej księgowości
    (rozliczanie VAT, rozliczanie podatku dochodowego), rejestrację działalności, tworzenie biznesplanów, porady biznesowe,
    Payroll oraz roczne rozliczenia już od <u><b>€100</b></u>.`,
  },
  {
    type: "quote",
    text: `D&M SERVICES:<br/>Twoja firma księgowo-doradcza w irlandii`,
  },
  {
    type: "paragraph",
    text: `W Dublinie nasza obecna siedziba znajduje się pod adresem Unit 26,
    Park West Enterprise Centre Park West Business Park, Dublin 12.`,
  },
  {
    fontSize: 18,
    type: "quote",
    text: `Powierz swoje rozliczenia sprawdzonemu biuru księgowemu <br/>
    tylko wtedy masz pewność profesjonalnej współpracy, <br/>
    dogodnych warunków i korzystnych cen.`,
  },
  {
    type: "paragraph",
    text: `Biuro Księgowe D&M Accountancy Services to <b><u>ponad 10 lat doświadczenia
    (w tym 7 lat działalności w Dublinie)</u></b> i setki zadowolonych Klientów.
    Oferujemy kompleksowe usługi księgowe:`,
  },
  {
    type: "paragraph",
    text: `<ul class='about-us-bullets'><li>rozliczenia podatku VAT,</li>
    <li>rozliczenia podatku dochodowego,</li>
    <li>obsługę list płac (Payroll),</li>
    <li>rejestrację działalności gospodarczych,</li>
    <li>tworzenie biznesów planów,</li>
    <li>porady biznesowe.</li></ul>`,
  },
  {
    type: "paragraph",
    text: `Z naszym biurem zyskasz gwarancję profesjonalnych rozliczeń i atrakcyjnej ceny.
    Rozliczenia roczne wykonujemy już w kwocie od <b><u>€100</b></u>.
    Jeżeli chcesz rozliczyć zwrot podatku, wystarczy, że napiszesz <a href='/kontakt'>e-maila</a>
    ze swoim imieniem i nazwiskiem oraz numerem PPS i zapytaniem o ewentualny zwrot,
    a my sprawdzimy informacje w systemie i niezwłocznie odpowiemy.`,
  },
  {
    fontSize: 18,
    type: "quote",
    text: `Współpracując z D&M Accountancy Services, masz pewność,
    że Twoje finanse są w dobrych rękach. Możesz spać spokojnie i cieszyć się z
    możliwości współpracy z profesjonalistami posiadającymi ogromną wiedzę i doświadczenie.`,
  },
]

export default data
