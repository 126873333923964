import React from "react"

function createMarkup(text) {
  return { __html: text }
}

const BigQuote = ({ text, fontSize }) => (
  <div className="quote-big-wrapper">
    <h4
      className="quote-big"
      style={{ fontSize }}
      dangerouslySetInnerHTML={createMarkup(text)}
    ></h4>
  </div>
)

export default BigQuote
