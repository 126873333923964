import React from "react"

import Page from "../../components/Page"
import Quote from "../../components/Site/Quote"
import Paragraph from "../../components/Site/Paragraph"
import PricingForm from "../../components/PricingForm"

const ForCompanies = () => {
  const text = `Udzielamy wszystkich informacji, dotyczących <b>prawa podatkowego i pracowniczego</b>,
  które są niezbędne do prowadzenia własnego biznesu na terenie Irlandii. 
  Staramy się optymalizować wysokość płaconych podatków,
   informujemy o korzyściach i stratach płynących z danego <b>rozliczenia z Revenue.</b>`

  return (
    <Page title="dla firm">
      <h2 className="site-title">Usługi dla firm</h2>
      <div className='pricing-wrapper'>
        <div className='pricing-text'>
          <b>D&M Services swiadczy następujące usługi dla firm:</b>
          <ul className='about-us-bullets'>
            <li>
              rejestracja działalności gospodarczej
            </li>
            <li>
              rejestracja i rozliczanie podwykonawców w systemie RCT
            </li>
            <li>
              roczne rozliczenia podatkowe
            </li>
            <li>
              rozliczenia VAT
            </li>
            <li>
              payroll lista płac
            </li>
            <li>
              biznes plany i analizy finansowe
            </li>
            <li>
              wnioski o Tax Clearance
            </li>
            <li>
              rejestracja podmiotu w CRO
            </li>
          </ul>
          <Paragraph text={text} />
          <Quote
            text="Służymy naszym klientom radą nie tylko w zakresie księgowości, lecz także prawa, rozwoju biznesu, inwestycji oraz ubezpieczeń. Współpracujemy z biegłymi rewidentami oraz prawnikami renomowanych irlandzkich kancelarii." />

        </div>
        <PricingForm />
      </div>
    </Page>
  )
}

export default ForCompanies
