import React from "react"
import { NavLink } from "react-router-dom"

const MenuItem = ({ Icon, name, url, submenu }) => {
  return (
    <div className='menu-item-wrapper'>
      <NavLink to={url} exact>
        <li className="menu-item">
          <div className="menu-item-icon">
            <Icon />
          </div>
          <p className="menu-item-text">{name}</p>
        </li>
      </NavLink>
      {submenu.length !== 0 && (
        <ol className="sub-menu">
          {submenu.map((subItem) => (
            <NavLink to={subItem.url} exact>
              <li className="sub-menu-item">
                <p className="sub-menu-item-text">{subItem.name}</p>
              </li>
            </NavLink>
          ))}
        </ol>
      )}
    </div>
  )
}

export default MenuItem
