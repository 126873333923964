import React, { useState } from "react"

import data from "./data"
import MenuItem from "./MenuItem"
import Logo from "../../../assets/images/logo.png"

const Menu = () => {
  const [active, setActive] = useState(false)

  return (
    <React.Fragment>
      <nav className="menu-wrapper">
        <img src={Logo} className="menu-logo" />
        <ul className="menu-lister">
          {data.map((menuItem) => (
            <MenuItem {...menuItem} />
          ))}
        </ul>
        {/*<div className="menu-blur" />*/}
      </nav>
      <nav className={`menu-wrapper mobile ${active && "shown"}`}>
        <img src={Logo} className="menu-logo" />
        <ul className="menu-lister">
          {active && data.map((menuItem) => <MenuItem {...menuItem} />)}
          <div onClick={() => setActive(!active)} className="menu-switch">
            <div className="menu-line first" />
            <div className="menu-line second" />
            <div className="menu-line third" />
          </div>
        </ul>
        {/*<div className="menu-blur" />*/}
      </nav>
    </React.Fragment>
  )
}

export default Menu
