import React from "react"

import * as database from "../database"
import Quote from "./Site/Quote"

export default class PricingForm extends React.Component {
  state = {
    name: "",
    nameError: { on: false, text: "" },
    lastname: "",
    lastnameError: { on: false, text: "" },
    email: "",
    emailError: { on: false, text: "" },
    msg: "",
    msgError: { on: false, text: "" },
    phone: "",
    phoneError: { on: false, text: "" },
    workersNumber: "",
    workersNumberError: { on: false, text: "" },
    documentsNumber: "",
    documentsNumberError: { on: false, text: "" },
    companyStructure: "",
    companyStructureError: { on: false, text: "" },
    sector: "",
    sectorError: { on: false, text: "" },
    vat: "",
    vatError: { on: false, text: "" },
  }

  handleOnChange = (event) => {
    if (event.currentTarget.value === "")
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value,
        [`${event.currentTarget.name}Error`]: {
          on: true,
          text: "- nie może być puste",
        },
      })
    else
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value,
        [`${event.currentTarget.name}Error`]: { on: false },
      })
  }

  handleSubmit =async (event) => {
    event.preventDefault()
    const { name, lastname, email, msg, workersNumber, documentsNumber, companyStructure, vat, phone, sector } = this.state
    let error = false

    if (name === "") {
      error = true
      this.setState({
        nameError: { on: true, text: "- nie może być puste" },
      })
    }
    if (lastname === "") {
      error = true
      this.setState({
        lastnameError: { on: true, text: "- nie może być puste" },
      })
    }
    if (email === "") {
      error = true
      this.setState({
        emailError: { on: true, text: "- nie może być puste" },
      })
    }
    if (phone === "") {
      error = true
      this.setState({
        phoneError: { on: true, text: "- nie może być puste" },
      })
    }
    if (workersNumber === "") {
      error = true
      this.setState({
        workersNumberError: { on: true, text: "- nie może być puste" },
      })
    }
    if (documentsNumber === "") {
      error = true
      this.setState({
        documentsNumberError: { on: true, text: "- nie może być puste" },
      })
    }
    if (companyStructure === "") {
      error = true
      this.setState({
        companyStructureError: { on: true, text: "- nie może być puste" },
      })
    }
    if (vat === "") {
      error = true
      this.setState({
        vatError: { on: true, text: "- nie może być puste" },
      })
    }
    if (sector === "") {
      error = true
      this.setState({
        sectorError: { on: true, text: "- nie może być puste" },
      })
    }

    if (error) return

    let mail = `
    Od: ${name} ${lastname}
    Adres email:${email}
    Telefon:${phone}
    
    VAT:${vat}
    Struktura firmy:${companyStructure}
    Sektor firmy:${sector}
    Liczba pracowników:${workersNumber}
    Liczba dokumentów:${documentsNumber}
    
    Wiadomość:
    ${msg}
    `

   await database.mail(mail)
  }

  render() {
    const {
      name,
      nameError,
      lastname,
      lastnameError,
      email,
      emailError,
      msg,
      msgError,
      phone,
      phoneError,
      documentsNumberError,
      workersNumber,
      workersNumberError,
      companyStructureError,
      sectorError,
      vatError,
    } = this.state

    return (
      <form onSubmit={this.handleSubmit} className="fast-contact-form">
        <Quote text={"Szybki kontakt"} />
        <label htmlFor="contact-form-name" className="form-contact-label">
          <p className="form-contact-name">
            Imię {nameError.on && nameError.text}
          </p>
          <input
            type="text"
            name="name"
            className={`form-input ${nameError.on && "error"}`}
            id="contact-form-name"
            onChange={this.handleOnChange}
            defaultValue={name}
          />
        </label>
        <label htmlFor="contact-form-lastname" className="form-contact-label">
          <p className="form-contact-name">
            Nazwisko {lastnameError.on && lastnameError.text}
          </p>
          <input
            type="text"
            name="lastname"
            className={`form-input ${lastnameError.on && "error"}`}
            id="contact-form-lastname"
            onChange={this.handleOnChange}
            defaultValue={lastname}
          />
        </label>
        <label htmlFor="contact-form-email" className="form-contact-label">
          <p className="form-contact-name">
            Email: {emailError.on && emailError.text}
          </p>
          <input
            type="email"
            name="email"
            className={`form-input ${emailError.on && "error"}`}
            id="contact-form-email"
            onChange={this.handleOnChange}
            defaultValue={email}
          />
        </label>
        <label htmlFor="contact-form-phone" className="form-contact-label">
          <p className="form-contact-name">
            Telefon: {phoneError.on && phoneError.text}
          </p>
          <input
            type="number"
            name="phone"
            className={`form-input ${phoneError.on && "error"}`}
            id="contact-form-phone"
            onChange={this.handleOnChange}
            defaultValue={phone}
          />
        </label>
        <label htmlFor="contact-form-workers-number" className="form-contact-label">
          <p className="form-contact-name">
            Liczba pracowników: {workersNumberError.on && workersNumberError.text}
          </p>
          <input
            type="number"
            name="workersNumber"
            className={`form-input ${workersNumberError.on && "error"}`}
            id="contact-form-workers-number"
            onChange={this.handleOnChange}
            defaultValue={workersNumber}
          />
        </label>
        <label htmlFor="contact-form-documents-number" className="form-contact-label">
          <p className="form-contact-name">
            Ilość dokumentów w miesiącu: {documentsNumberError.on && documentsNumberError.text}
          </p>
          <select
            name="documentsNumber"
            id="contact-form-documents-number"
            className={`form-input ${documentsNumberError.on && "error"}`}
            onChange={this.handleOnChange}
          >
            <option>----------------</option>
            <option>1 - 10</option>
            <option>11 - 20</option>
            <option>21 - 50</option>
            <option>ponad 50</option>
          </select>
        </label>
        <label htmlFor="contact-form-company-structure" className="form-contact-label">
          <p className="form-contact-name">
            Struktura firmy: {companyStructureError.on && companyStructureError.text}
          </p>
          <select
            name="companyStructure"
            id="contact-form-company-structure"
            className={`form-input ${companyStructureError.on && "error"}`}
            onChange={this.handleOnChange}
          >
            <option>----------------</option>
            <option>Sole trader</option>
            <option>Limited company</option>
            <option>Partnership</option>
          </select>
        </label>
        <label htmlFor="contact-form-sector" className="form-contact-label">
          <p className="form-contact-name">
            Sektor: {sectorError.on && sectorError.text}
          </p>
          <select
            name="sector"
            id="contact-form-sector"
            className={`form-input ${sectorError.on && "error"}`}
            onChange={this.handleOnChange}
          >
            <option>----------------</option>
            <option>Usługi budowlane</option>
            <option>Działalność handlowa</option>
            <option>Warsztat samochodowy</option>
            <option>Branża ICT</option>
            <option>Usługi</option>
            <option>Inne</option>
          </select>
        </label>
        <label htmlFor="contact-form-vat" className="form-contact-label">
          <p className="form-contact-name">
            Płatnik VAT: {vatError.on && vatError.text}
          </p>
          <select
            name="vat"
            id="contact-form-vat"
            className={`form-input ${vatError.on && "error"}`}
            onChange={this.handleOnChange}
          >
            <option>----------------</option>
            <option>TAK</option>
            <option>NIE</option>
          </select>
        </label>
        <label htmlFor="contact-form-message" className="form-contact-label">
          <p className="form-contact-name">
            Uwagi {msgError.on && msgError.text}
          </p>
          <textarea
            name="msg"
            className={`form-input-wide ${msgError.on && "error"}`}
            id="contact-form-message"
            onChange={this.handleOnChange}
            defaultValue={msg}
          />
        </label>
        <input type="submit" className="form-submit" />
      </form>
    )
  }
}
