import React from "react"

function createMarkup(text) {
  return { __html: text }
}

const Quote = ({ text, fontSize, noCursive }) => (
  <h4
    className={`quote ${noCursive ? "no-c":'yes-c'}`}
    style={{ fontSize }}
    dangerouslySetInnerHTML={createMarkup(text)}
  ></h4>
)

export default Quote
