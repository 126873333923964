import * as actions from "../actionNames/popups"

const popups = (state = { data: [] }, action) => {
  switch (action.type) {
    case actions.POPUPS_ADD:
      state.data.push(action.data)
      return state
    case actions.POPUPS_EDIT:
      let changedItem = state.data.findIndex(
        (item) => item.id === action.data.id
      )
      state.data[changedItem] = action.data
      return state
    case actions.POPUPS_REMOVE:
      state.data = state.data.filter((item) => item.id !== action.data)
      return state
    case actions.POPUPS_SET:
      return { ...state, data: action.data }
    case actions.POPUPS_STATUS:
      return { ...state, status: action.status }
    default:
      return state
  }
}

export default popups
