import { addPopup, settings } from "./utils"

const mail = async (mail, file = null) => {

  const form = new FormData()
  form.append("mail", mail)
  form.append("file", file)

  console.log(file)

  await fetch("/api/mailer.php",
    {
      method: "POST",
      body: form,
    }).then(res => window.location.assign('/'))
    .catch(err => console.error(err))
}

export default mail
