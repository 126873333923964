import React from "react"

import * as database from "../database"

export default class ContactForm extends React.Component {
  state = {
    name: "",
    nameError: { on: false, text: "" },
    lastname: "",
    lastnameError: { on: false, text: "" },
    pps: null,
    ppsError: { on: false, text: "" },
    email: "",
    emailError: { on: false, text: "" },
    msg: "",
    msgError: { on: false, text: "" },
  }

  handleOnChange = (event) => {
    if (event.currentTarget.value === "")
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value,
        [`${event.currentTarget.name}Error`]: {
          on: true,
          text: "- nie może być puste",
        },
      })
    else
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value,
        [`${event.currentTarget.name}Error`]: { on: false },
      })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { name, lastname, pps, email, msg } = this.state
    let error = false

    if (name === "") {
      error = true
      this.setState({
        nameError: { on: true, text: "- nie może być puste" },
      })
    }
    if (lastname === "") {
      error = true
      this.setState({
        lastnameError: { on: true, text: "- nie może być puste" },
      })
    }
    if (pps === "") {
      error = true
      this.setState({
        ppsError: { on: true, text: "- nie może być puste" },
      })
    }
    if (email === "") {
      error = true
      this.setState({
        emailError: { on: true, text: "- nie może być puste" },
      })
    }
    if (msg === "") {
      error = true
      this.setState({
        msgError: { on: true, text: "- nie może być puste" },
      })
    }

    if (error) return

    let mail = `
    Od: ${name} ${lastname}
    Numer PPS: ${pps}
    Adres email:${email}
    Wiadomość:
    ${msg}
    `

   await database.mail(mail)
  }

  render() {
    const {
      name,
      nameError,
      lastname,
      lastnameError,
      pps,
      ppsError,
      email,
      emailError,
      msg,
      msgError,
    } = this.state

    return (
      <form onSubmit={this.handleSubmit} className="contact-form">
        <label htmlFor="contact-form-name" className="form-contact-label">
          <p className="form-contact-name">
            Imię {nameError.on && nameError.text}
          </p>
          <input
            type="text"
            name="name"
            className={`form-input ${nameError.on && "error"}`}
            id="contact-form-name"
            onChange={this.handleOnChange}
            defaultValue={name}
          />
        </label>
        <label htmlFor="contact-form-lastname" className="form-contact-label">
          <p className="form-contact-name">
            Nazwisko {lastnameError.on && lastnameError.text}
          </p>
          <input
            type="text"
            name="lastname"
            className={`form-input ${lastnameError.on && "error"}`}
            id="contact-form-lastname"
            onChange={this.handleOnChange}
            defaultValue={lastname}
          />
        </label>
        <label htmlFor="contact-form-pps" className="form-contact-label">
          <p className="form-contact-name">
            Numer PPS {ppsError.on && ppsError.text}
          </p>
          <input
            type="number"
            name="pps"
            className={`form-input ${ppsError.on && "error"}`}
            id="contact-form-pps"
            onChange={this.handleOnChange}
            defaultValue={pps}
          />
        </label>
        <label htmlFor="contact-form-email" className="form-contact-label">
          <p className="form-contact-name">
            Email: {emailError.on && emailError.text}
          </p>
          <input
            type="email"
            name="email"
            className={`form-input ${emailError.on && "error"}`}
            id="contact-form-email"
            onChange={this.handleOnChange}
            defaultValue={email}
          />
        </label>
        <label htmlFor="contact-form-message" className="form-contact-label">
          <p className="form-contact-name">
            Wiadomość {msgError.on && msgError.text}
          </p>
          <textarea
            name="msg"
            className={`form-input-wide ${msgError.on && "error"}`}
            id="contact-form-message"
            onChange={this.handleOnChange}
            defaultValue={msg}
          />
        </label>
        <input type="submit" className="form-submit" />
      </form>
    )
  }
}
