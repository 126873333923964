import React from "react"

import Page from "../components/Page"
import Quote from "../components/Site/Quote"
import Paragraph from "../components/Site/Paragraph"
import Title from "../components/Site/Title"
import data from "../data/AboutUs"
import FacebookPanelTall from "../components/FacebookPanelTall"

const AboutUs = () => (
  <Page title="o nas">
    <Title text="o nas" />
    <div className="sides-wrapper">
      <div className="left-side">
        {data.map((item) => {
          return item.type === "paragraph" ? (
            <Paragraph text={item.text} />
          ) : (
            <Quote text={item.text} fontSize={item.fontSize} />
          )
        })}
      </div>
      <div className="right-side-wrapper">
        <div className="right-side">
          <Quote text="Nasz FB" />
          <div style={{ minWidth: "300px" }}>
            <FacebookPanelTall />
          </div>
        </div>
      </div>
    </div>
  </Page>
)

export default AboutUs
