import React from "react"
import translator from "../assets/translations/translator"

import Page from "../components/Page"

const Page401 = () => (
  <Page title={translator("unauthed")}>{translator("unauthed")}</Page>
)

export default Page401
