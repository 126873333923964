import React from "react"

import * as database from "../database"
import Quote from "./Site/Quote"

export default class PricingForm extends React.Component {
  state = {
    name: "",
    nameError: { on: false, text: "" },
    lastname: "",
    lastnameError: { on: false, text: "" },
    email: "",
    emailError: { on: false, text: "" },
    msg: "",
    msgError: { on: false, text: "" },
    phone: "",
    phoneError: { on: false, text: "" },
    maritalStatus: "",
    maritalStatusError: { on: false, text: "" },
    social: "",
    socialError: { on: false, text: "" },
    file: "",
    fileError: { on: false, text: "" },
    medicCosts: "",
    medicCostsError: { on: false, text: "" },
  }

  handleOnChange = (event) => {
    try {
      this.setState({
        [event.currentTarget.name]: event.currentTarget.files[0],
        [`${event.currentTarget.name}Error`]: { on: false },
      })
    } catch {
      if (event.currentTarget.value === "") {
        this.setState({
          [event.currentTarget.name]: event.currentTarget.value,
          [`${event.currentTarget.name}Error`]: {
            on: true,
            text: "- nie może być puste",
          },
        })
      } else {
        this.setState({
          [event.currentTarget.name]: event.currentTarget.value,
          [`${event.currentTarget.name}Error`]: { on: false },
        })
      }
    }
  }

  handleSubmit =async (event) => {
    event.preventDefault()
    const { name, lastname, email, msg, phone, maritalStatus, medicCosts, social, file } = this.state
    let error = false

    if (name === "") {
      error = true
      this.setState({
        nameError: { on: true, text: "- nie może być puste" },
      })
    }
    if (lastname === "") {
      error = true
      this.setState({
        lastnameError: { on: true, text: "- nie może być puste" },
      })
    }
    if (email === "") {
      error = true
      this.setState({
        emailError: { on: true, text: "- nie może być puste" },
      })
    }
    if (phone === "") {
      error = true
      this.setState({
        phoneError: { on: true, text: "- nie może być puste" },
      })
    }
    if (maritalStatus === "") {
      error = true
      this.setState({
        maritalStatusError: { on: true, text: "- nie może być puste" },
      })
    }
    if (social === "") {
      error = true
      this.setState({
        socialError: { on: true, text: "- nie może być puste" },
      })
    }
    if (medicCosts === "") {
      error = true
      this.setState({
        medicCostsError: { on: true, text: "- nie może być puste" },
      })
    }

    if (error) return

    let mail = `
    Od: ${name} ${lastname}
    Adres email: ${email}
    Telefon: ${phone}
    
    Stan cywilny: ${maritalStatus}
    Czy w ciagu 4 lat wstecz, były pobierane świadczenia socjalne takie jak zasiłek, chorobowe? ${social}
    Czy zostały poniesione jakies koszty medyczne w ostatnich 4 latach? ${medicCosts}
    
    Wiadomość:
    ${msg}
    `

    await database.mail(mail, file)
  }

  render() {
    const {
      name,
      nameError,
      lastname,
      lastnameError,
      email,
      emailError,
      msg,
      msgError,
      phone,
      phoneError,
      maritalStatusError,
      socialError,
      medicCostsError,
      fileError,
    } = this.state

    return (
      <form onSubmit={this.handleSubmit} className="fast-contact-form" encType="multipart/form-data">
        <Quote text={"Szybki kontakt"} />
        <label htmlFor="contact-form-name" className="form-contact-label">
          <p className="form-contact-name">
            Imię {nameError.on && nameError.text}
          </p>
          <input
            type="text"
            name="name"
            className={`form-input ${nameError.on && "error"}`}
            id="contact-form-name"
            onChange={this.handleOnChange}
            defaultValue={name}
          />
        </label>
        <label htmlFor="contact-form-lastname" className="form-contact-label">
          <p className="form-contact-name">
            Nazwisko {lastnameError.on && lastnameError.text}
          </p>
          <input
            type="text"
            name="lastname"
            className={`form-input ${lastnameError.on && "error"}`}
            id="contact-form-lastname"
            onChange={this.handleOnChange}
            defaultValue={lastname}
          />
        </label>
        <label htmlFor="contact-form-email" className="form-contact-label">
          <p className="form-contact-name">
            Email: {emailError.on && emailError.text}
          </p>
          <input
            type="email"
            name="email"
            className={`form-input ${emailError.on && "error"}`}
            id="contact-form-email"
            onChange={this.handleOnChange}
            defaultValue={email}
          />
        </label>
        <label htmlFor="contact-form-phone" className="form-contact-label">
          <p className="form-contact-name">
            Telefon: {phoneError.on && phoneError.text}
          </p>
          <input
            type="number"
            name="phone"
            className={`form-input ${phoneError.on && "error"}`}
            id="contact-form-phone"
            onChange={this.handleOnChange}
            defaultValue={phone}
          />
        </label>
        <label htmlFor="contact-form-maritalStatus" className="form-contact-label">
          <p className="form-contact-name">
            Stan cywilny: {maritalStatusError.on && maritalStatusError.text}
          </p>
          <select
            name="maritalStatus"
            id="contact-form-maritalStatus"
            className={`form-input ${maritalStatusError.on && "error"}`}
            onChange={this.handleOnChange}
          >
            <option>----------------</option>
            <option>Wolny</option>
            <option>W związku małżeńskim</option>
          </select>
        </label>
        <label htmlFor="contact-form-social" className="form-contact-label">
          <p className="form-contact-name">
            Czy w ciagu 4 lat wstecz, były pobierane świadczenia socjalne takie jak zasiłek,
            chorobowe? {socialError.on && socialError.text}
          </p>
          <select
            name="social"
            id="contact-form-social"
            className={`form-input ${socialError.on && "error"}`}
            onChange={this.handleOnChange}
          >
            <option>----------------</option>
            <option>TAK</option>
            <option>NIE</option>
          </select>
        </label>
        <label htmlFor="contact-form-medicCosts" className="form-contact-label">
          <p className="form-contact-name">
            Czy zostały poniesione jakies koszty medyczne w ostatnich 4
            latach? {medicCostsError.on && medicCostsError.text}
          </p>
          <select
            name="medicCosts"
            id="contact-form-medicCosts"
            className={`form-input ${medicCostsError.on && "error"}`}
            onChange={this.handleOnChange}
          >
            <option>----------------</option>
            <option>TAK</option>
            <option>NIE</option>
          </select>
        </label>
        <label htmlFor="contact-form-file" className="form-contact-label">
          <p className="form-contact-file">
            Skan P60: {fileError.on && fileError.text}
          </p>
          <input
            type="file"
            name="file"
            className={`form-input ${fileError.on && "error"}`}
            id="contact-form-file"
            onChange={this.handleOnChange}
          />
        </label>
        <label htmlFor="contact-form-message" className="form-contact-label">
          <p className="form-contact-name">
            Uwagi {msgError.on && msgError.text}
          </p>
          <textarea
            name="msg"
            className={`form-input-wide ${msgError.on && "error"}`}
            id="contact-form-message"
            onChange={this.handleOnChange}
            defaultValue={msg}
          />
        </label>
        <input type="submit" className="form-submit" />
      </form>
    )
  }
}
