import React from "react"
import { v4 } from "uuid"

import Home from "../pages/Home"
import AboutUs from "../pages/AboutUs"
import Contact from "../pages/Contact"
import Main from "../pages/Services/Main"
import Pricing from "../pages/Pricing"
import Correspondence from "../pages/Services/Corespondence"
import P60 from "../pages/Services/P60"
import TaxRelief from "../pages/Services/TaxRelief"
import ForCompanies from "../pages/Services/ForCompanies"
import VirtualOffice from "../pages/Services/VirutalOffice"

const mainRoutes = [
  {
    id: v4(),
    path: "/",
    render: <Home />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/o-nas",
    render: <AboutUs />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/kontakt",
    render: <Contact />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/cennik",
    render: <Pricing />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/uslugi",
    render: <Main />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/uslugi/dla-firm",
    render: <ForCompanies />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/uslugi/zwrot-z-p60",
    render: <P60 />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/uslugi/ksiegowosc-korespondencyjna",
    render: <Correspondence />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/uslugi/ulga-podatkowa",
    render: <TaxRelief />,
    exact: true,
    role: 0,
  },
  {
    id: v4(),
    path: "/uslugi/wirtualne-biuro",
    render: <VirtualOffice />,
    exact: true,
    role: 0,
  },
]

export default mainRoutes
