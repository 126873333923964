import React from "react"
import { NavLink } from "react-router-dom"

const Tile = ({ image, name, url }) => {
  return (
    <NavLink to={url}>
      <div className="tile" style={{ backgroundImage: `url(${image})` }}>
        <div className="tile-wrapper">
          <div className="tile-title">{name}</div>
        </div>
      </div>
    </NavLink>
  )
}

export default Tile
