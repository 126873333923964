import * as Icons from "../../../assets/icons"

const data = [
  {
    id: "menu-item-1",
    key: "menu-item-1",
    url: "/",
    name: "Główna",
    Icon: Icons.Home,
    exact: true,
    submenu: [],
  },
  {
    id: "menu-item-2",
    key: "menu-item-2",
    url: "/o-nas",
    name: "O nas",
    Icon: Icons.AboutUs,
    exact: true,
    submenu: [],
  },
  {
    id: "menu-item-3",
    key: "menu-item-3",
    url: "/kontakt",
    name: "Kontakt",
    Icon: Icons.ContactUs,
    exact: true,
    submenu: [],
  },
  {
    id: "menu-item-4",
    key: "menu-item-4",
    url: "/cennik",
    name: "Cennik",
    Icon: Icons.Pricing,
    exact: true,
    submenu: [],
  },
  {
    id: "menu-item-5",
    key: "menu-item-5",
    url: "/uslugi",
    name: "Usługi",
    Icon: Icons.Services,
    exact: true,
    submenu: [
      {
        id: "menu-item-5-b",
        key: "menu-item-5-b",
        url: "/uslugi/zwrot-z-p60",
        name: "Zwrot z P60",
        Icon: Icons.Services,
        exact: true,
      },
      {
        id: "menu-item-5-a",
        key: "menu-item-5-a",
        url: "/uslugi/dla-firm",
        name: "Dla firm",
        Icon: Icons.Services,
        exact: true,
      },
      {
        id: "menu-item-5-c",
        key: "menu-item-5-c",
        url: "/uslugi/ksiegowosc-korespondencyjna",
        name: "Księgowość korespondencyjna",
        Icon: Icons.Services,
        exact: true,
      },
      {
        id: "menu-item-5-d",
        key: "menu-item-5-d",
        url: "/uslugi/ulga-podatkowa",
        name: "Ulga podatkowa",
        Icon: Icons.Services,
        exact: true,
      },
      {
        id: "menu-item-5-e",
        key: "menu-item-5-e",
        url: "/uslugi/wirtualne-biuro",
        name: "Wirtualne biuro",
        Icon: Icons.Services,
        exact: true,
      },
    ],
  },
]

export default data
