import React from "react"

import Page from "../components/Page"
import ContactDetails from "../components/ContactDetails"
import ContactForm from "../components/ContactForm"
import Quote from "../components/Site/Quote"

const Contact = () => (
  <Page title="kontakt">
    <h2 className="site-title">Kontakt</h2>
    <ContactDetails />
    <Quote text="Spotkaj się z nami i dowiedz się ile Ci się należy!" />
    <ContactForm />
    <Quote text="Napisz do nas i dowiedz się ile Ci się należy!" />
  </Page>
)

export default Contact
