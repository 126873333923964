import * as actions from "../actionNames/exampleStore"

const exampleStore = (state = { data: [] }, action) => {
  switch (action.type) {
    case actions.EX_ADD:
      state.data.push(action.data)
      return state
    case actions.EX_EDIT:
      let changedItem = state.data.findIndex(
        (item) => item.id === action.data.id
      )
      state.data[changedItem] = action.data
      return state
    case actions.EX_REMOVE:
      state.data = state.data.filter((item) => item.id !== action.data)
      return state
    case actions.EX_SET:
      return { ...state, data: action.data }
    case actions.EX_STATUS:
      return { ...state, status: action.status }
    default:
      return state
  }
}

export default exampleStore
